import React, { PureComponent, Fragment } from "react";
import Footer from './containers/Footer';
import Home from './containers/Home';
import './style/App.scss';
import Navbar from './containers/Navigation';
class App extends PureComponent {

  state = {
    onLoad: true
  }

  initPage = () => {
    this.setState({
      onLoad: false
    });
  }

  componentDidMount() {
    this.initPage();
  }

  render() {
    return (
      this.state.onLoad
      ?
        <div className="loading-main">
          {/* <div className="loading-img"></div> */}
          <div className="loading-content">
            <img className="loading-img" src={require('./asset/Loading3.gif')} alt=""/>
            <p className="loading-text">載入頁面中</p>
          </div>
        </div>
      :
      <Fragment>
        <Navbar />
        <Home />
      </Fragment>
    );
  }
}

export default App;
