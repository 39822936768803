import React, { PureComponent, Fragment } from "react";
import { initGA, logPageView } from '../../utils/analytics';
import './Home.scss';
import video from '../../asset/video.mp4';
import Footer from '../Footer';
class Home extends PureComponent {

  componentDidMount(){
    if (!window.GA_INITIALIZED) {
        initGA();
        window.GA_INITIALIZED = true;
    }
    logPageView();
    this.playVideo();
  }

  componentWillUnmount = () => {
    this.pauseVideo();
};


  playVideo = () => {
    // You can use the play method as normal on your video ref
    this.refs.vidRef.play();
  };

  pauseVideo = () => {
    // Pause as well
    this.refs.vidRef.pause();
  };


  render() {
    return (
        <Fragment>
          <div className="main-page">
              <div className="introduce-container">
                  <div className="introduce-block">
                    <h1 className="introduce-title">努力從生活中獲取經驗中</h1>
                    <p className="introduce-content">人生的旅途，總是會碰到各種挫折，儘管是成功與失敗，經驗是最好的成果與證明。讓我們一起努力經歷這場未知的旅途吧！</p>
                    <div className="tag-content">
                      <p className="tag"># 旅遊</p>
                      <p className="tag"># 網頁工程師</p>
                      <p className="tag"># 攝影</p>
                      <p className="tag"># 參加社群</p>
                    </div>
                </div>
                <video 
                  src={video}
                  ref="vidRef"
                  type="video/mp4"
                />              
              </div>
              <h3 class="new-post-brand">精選文章</h3>
              <ul class="new-post-list">
                  <li class="new-post-element" onClick={()=>window.open('https://blog.yyisyou.tw/1789bf82/', '_blank')}>
                      <h4 class="new-post-title">使用OSM與leaflet開發map</h4>
                      <p class="new-post-content">最近因為公司需求，除了需要學習React之外，也要隨時預備一些舊產品重構成Vue的準備，像是最近公司的讀書會主題都以Vue為主了...</p>
                  </li>
                  <li class="new-post-element" onClick={()=>window.open('https://blog.yyisyou.tw/5ac95a76/', '_blank')}>
                      <h4 class="new-post-title">如何加強網站的SEO - 基礎篇</h4>
                      <p class="new-post-content">我們設計一個上線的網站，最重要的當然就是曝光自己的網站，並且把流量拉起來提高客群。一般使用者都會透過搜尋引擎，如Google, Yahoo...</p>
                  </li>
                  <li class="new-post-element" onClick={()=>window.open('https://blog.yyisyou.tw/d5b50908/', '_blank')}>
                      <h4 class="new-post-title">給新鮮人的職場生態分享</h4>
                      <p class="new-post-content">職場生活總是會遇到各種沒碰過的事情，有些事和學生時期遇到的事情類似，但是有些事情事要親自到職場才能深刻體會的。</p>
                  </li>
              </ul>
          </div>
          <Footer />
        </Fragment>
        )
  };
};

export default Home;