import React, { PureComponent } from "react";
import './Navigation.scss';
class Navigation extends PureComponent {

    state = {
        toggled: false,
        menuStyle: "0em"
    }

    menuToggle = () => {   
        if(this.state.toggled) {
            this.setState({
                toggled: false,
                menuStyle: "0em"
            });
        } else {
            this.setState({
                toggled: true,
                menuStyle: "100em"
            });
        }
    }

    render() {
        // const iconStyle = "font-size: .8em;";
        
        return (
            <div class="nav">
                <ul>
                    <li>
                        <a href="https://www.yyisyou.tw/" class="nav-link">
                            <img src="https://library.kissclipart.com/20181206/xkq/kissclipart-custom-cute-puppy-pillow-case-clipart-puppy-siberi-318245f9c41bddf6.jpg"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://blog.yyisyou.tw/" class="nav-link">Blog</a>
                    </li>
                    <li>
                        <a href="https://medium.yyisyou.tw/" class="nav-link">Medium</a>
                    </li>
                    <li>
                        <a href="https://tools.yyisyou.tw/" class="nav-link">YY's Lab</a>
                    </li>
                </ul>
            </div>
            // <div>
            // <Navbar className="navbar" expand="sm" variant="dark">
            //     <Navbar.Brand href="https://www.yyisyou.tw/">Yang Yang</Navbar.Brand>
            //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            //     <Navbar.Collapse id="responsive-navbar-nav">
            //         <Nav className="mr-auto nav-ul">
            //             <Nav.Link className="nav-li" href="https://blog.yyisyou.tw/" target="_blank">Blog</Nav.Link>
            //             <Nav.Link className="nav-li" href="https://medium.yyisyou.tw/" target="_blank">Medium</Nav.Link>
            //             <Nav.Link className="nav-li" href="https://linkedin.yyisyou.tw/" target="_blank">LinkedIn</Nav.Link>
            //             <Nav.Link className="nav-li" href="https://github.yyisyou.tw/" target="_blank">GitHub</Nav.Link>
            //             <Nav.Link className="nav-li" href="https://tools.yyisyou.tw/" target="_blank">YY's Lab</Nav.Link>
            //             {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
            //                 <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            //                 <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            //                 <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            //                 <NavDropdown.Divider />
            //                 <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            //             </NavDropdown> */}
            //         </Nav>
            //     </Navbar.Collapse>
            // </Navbar>
            // </div>
            // <div>
            //     <div className="logo">
            //         <h1 className="logo-text">
            //             <span>
            //                 Awa
            //             </span>
            //             inspires
            //         </h1>
            //     </div>
            //     <i className="fa fa-bars menu-toggle" onClick={this.menuToggle} ></i>
            //     <ul className="nav" style={{maxHeight: this.state.menuStyle}} >
            //         <li><a href="https://yyisyou.tw/">Blog</a></li>
            //         <li><a href="https://www.linkedin.com/in/yangyangisyou/">LinkedIn</a></li>
            //         <li><a href="https://github.com/yangyangisyou">GitHub</a></li>
                    
            //         {/* <li>
            //             <a href="#">
            //                 <i className="fa fa-user" style={{ iconStyle }}></i>
            //                 Multi choice
            //                 <i className="fa fa-chevron-down"></i>
            //             </a>
            //             <ul style={{maxHeight: this.state.menuStyle}}>
            //                 <li><a href="#">A choice</a></li>
            //                 <li><a href="#">B choice</a></li>
            //             </ul>
            //         </li> */}
            //     </ul>
            // </div>
        );
    };
};

export default Navigation;