import React, { PureComponent } from "react";
import '../style/Footer.scss';
class Footer extends PureComponent {

  render() {
    return (
        <footer>
            <div className="footer-content">
                <p className="footer-copyright">©2020 Yang Yang All Rights Reserved</p>
            </div>
        </footer>
        )
  };
};

export default Footer;